import React, { useState, useEffect } from 'react'   
import { Timer, Bell } from 'lucide-react';

export const ComingSoonModal = ({ app, onClose, isDarkMode, setComingSoonEmail, setIsSubscribed, isSubscribed, comingSoonEmail }) => {
    const [timeLeft, setTimeLeft] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const releaseDate = new Date(app.releaseDate).getTime();
            const now = new Date().getTime();
            const difference = releaseDate - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                setTimeLeft({
                    days: days.toString().padStart(2, '0'),
                    hours: hours.toString().padStart(2, '0'),
                    minutes: minutes.toString().padStart(2, '0'),
                    seconds: seconds.toString().padStart(2, '0')
                });
            }
        };

        // Calculate immediately
        calculateTimeLeft();

        // Update every second
        const timer = setInterval(calculateTimeLeft, 1000);

        // Cleanup
        return () => clearInterval(timer);
    }, [app.releaseDate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubscribed(true);
        setComingSoonEmail('');
        setTimeout(() => {
            onClose();
            setIsSubscribed(false);
        }, 2000);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[70] flex items-center justify-center p-4" 
             onClick={onClose}>
            <div className={`max-w-3xl mx-auto p-8 rounded-2xl shadow-lg ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
            }`} onClick={(e) => e.stopPropagation()}>
                <div className="flex flex-col items-center text-center space-y-6">
                    <div className={`w-16 h-16 rounded-full flex items-center justify-center ${
                        isDarkMode ? 'bg-purple-900/30' : 'bg-purple-100'
                    }`}>
                        <Timer size={32} className="text-purple-600 animate-pulse" />
                    </div>

                    <div>
                        <h2 className={`text-3xl font-bold mb-2 ${
                            isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                            {app.name} - Coming Soon
                        </h2>
                        <p className={`text-lg ${
                            isDarkMode ? 'text-gray-300' : 'text-gray-600'
                        }`}>
                            Expected Release: {new Date(app.releaseDate).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })}
                        </p>
                    </div>

                    <div className="grid grid-cols-4 gap-4 w-full max-w-md">
                        {[
                            { value: timeLeft.days, label: 'Days' },
                            { value: timeLeft.hours, label: 'Hours' },
                            { value: timeLeft.minutes, label: 'Minutes' },
                            { value: timeLeft.seconds, label: 'Seconds' },
                        ].map((item) => (
                            <div key={item.label} className={`p-4 rounded-lg ${
                                isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                            }`}>
                                <div className={`text-2xl font-bold mb-1 ${
                                    isDarkMode ? 'text-white' : 'text-gray-900'
                                }`}>
                                    {item.value}
                                </div>
                                <div className={`text-sm ${
                                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                                }`}>
                                    {item.label}
                                </div>
                            </div>
                        ))}
                    </div>

                    {!isSubscribed ? (
                        <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4">
                            <div className="relative">
                                <input
                                    type="email"
                                    value={comingSoonEmail}
                                    onChange={(e) => setComingSoonEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    className={`w-full px-4 py-3 rounded-lg pl-10 ${
                                        isDarkMode 
                                            ? 'bg-gray-700 text-white placeholder-gray-400 border-gray-600' 
                                            : 'bg-gray-100 text-gray-900 placeholder-gray-500 border-gray-200'
                                    } border focus:outline-none focus:ring-2 focus:ring-purple-500`}
                                    required
                                />
                                <Bell className={`absolute left-3 top-3.5 h-5 w-5 ${
                                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                                }`} />
                            </div>
                            <button
                                type="submit"
                                className="w-full px-6 py-3 text-white bg-[#23292f] rounded-lg hover:bg-purple-700 transition-colors duration-200"
                            >
                                Notify Me When Released
                            </button>
                        </form>
                    ) : (
                        <div className={`p-4 rounded-lg ${
                            isDarkMode ? 'bg-green-900/30 text-green-300' : 'bg-green-100 text-green-700'
                        }`}>
                            Thanks for subscribing! We'll notify you when {app.name} launches.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};