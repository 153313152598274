import React from 'react'
import { Sun, Moon, Youtube, Instagram, Music } from 'lucide-react';
import { RiTiktokLine, RiInstagramLine, RiTwitterXLine } from "react-icons/ri";
import { FaGithub, FaDev } from "react-icons/fa";
import { SlSocialLinkedin } from "react-icons/sl";
import SocialMediaRow from './SocialMediaRow';

export default function Navigation({ isDarkMode, toggleDarkMode }) {
  return (
    <>
      {/* Mobile Header */}
      <div className="md:hidden bg-gradient-to-b from-sky-50 to-white">
        {/* Gradient Overlay */}
        <div className="relative overflow-hidden">

          {/* Hero Image */}
          <div className="relative w-full h-[60vh]">
            <img
              src="https://images.unsplash.com/photo-1662638600476-d563fffbb072?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Gaming Hero"
              className="w-full h-full object-cover"
            />
            {/* Bottom Gradient */}
            <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent"></div>

          </div>
          <div className="md:hidden">
            {/* Your existing mobile header code */}
            {/* <SocialMediaRow /> */}
          </div>
        </div>
      </div>

      {/* Desktop Navigation */}
      <nav className={`hidden md:block ${isDarkMode ? 'bg-gray-800' : 'bg-white'} border-b shadow-sm`}>
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <span className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Riki
              </span>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={toggleDarkMode}
                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-700 text-yellow-400' : 'bg-gray-100 text-gray-600'}`}
              >
                {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
              </button>
              <div className="flex space-x-4">
                <a href="https://dev.to/hosead6168" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <FaDev fontSize={24} />
                </a>
                <a href="https://www.linkedin.com/in/dominique-hosea/" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <SlSocialLinkedin fontSize={24} />
                </a>
                <a href="https://x.com/DominiqueRHosea" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <RiTwitterXLine fontSize={24} />
                </a>
                <a href="https://www.youtube.com/@HoseaWontMiss" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <Youtube />
                </a>
                <a href="https://github.com/HoseaCodes" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <FaGithub fontSize={24} />
                </a>
                <a href="https://www.tiktok.com/@hoseafitness" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <RiTiktokLine fontSize={24} />
                </a>
                <a href="https://www.instagram.com/hoseacodes/" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <RiInstagramLine fontSize={24} />
                </a>

              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
