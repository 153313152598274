import React from 'react'
import { SiRender, SiBrevo, SiGooglesheets, SiGooglecloud, SiVercel } from "react-icons/si";
import { FaAws } from "react-icons/fa";

export default function Footer({ isDarkMode }) {
  return (
    <footer className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} py-8`}>
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              © 2024 GameHub. All rights reserved.
              <div className={`flex gap-4`}>
                <a href="https://dashboard.render.com/" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <SiRender fontSize={24} />
                </a>
                <a href="https://vercel.com/infoambitiouscons-projects" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <SiVercel fontSize={24} />
                </a>
                <a href="https://docs.google.com/spreadsheets/d/1aGaGfPucJ-hmmnDHWZs2YQ2sF2IhE3H6PPw-_OiC38Y/edit?gid=0#gid=0" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <SiGooglesheets fontSize={24} />
                </a>
                <a href="https://console.cloud.google.com/apis/credentials?project=coldemail-433504" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <SiGooglecloud fontSize={24} />
                </a>
                <a href="https://aws.amazon.com/console/" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <FaAws fontSize={24} />
                </a>
                <a href="https://app-smtp.brevo.com/real-time" className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                  <SiBrevo fontSize={24} />
                </a>
              </div>
            </div>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a href="#" className={`text-sm ${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                Privacy Policy
              </a>
              <a href="#" className={`text-sm ${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                Terms of Service
              </a>
              <a href="#" className={`text-sm ${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </footer>
  )
}
