import React, { useState, useEffect } from 'react';
import { Mic, ExternalLink, Play } from 'lucide-react';
import IHeartEpisodes from './IHeartEpisodes';

const LatestEpisodes = ({ isDarkMode }) => {
  const [episodes, setEpisodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPodcasts = async () => {
      setIsLoading(true);
      setError(null); // Reset error state

      try {
        const [jreResponse, starTalkResponse] = await Promise.all([
          fetch('https://feeds.megaphone.fm/JRE'),
          fetch('https://feeds.megaphone.fm/startalk'),
        ]);

        if (!jreResponse.ok || !starTalkResponse.ok) {
          throw new Error('Failed to fetch podcast feeds');
        }

        const [jreText, starTalkText] = await Promise.all([
          jreResponse.text(),
          starTalkResponse.text(),
        ]);

        const parseFeed = (xmlText, channel) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(xmlText, 'text/xml');
          return Array.from(doc.querySelectorAll('item')).map((item) => ({
            id: item.querySelector('guid')?.textContent || Math.random().toString(),
            title: item.querySelector('title')?.textContent || 'Untitled Episode',
            description: item.querySelector('description')?.textContent || 'No description available',
            pubDate: new Date(item.querySelector('pubDate')?.textContent || Date.now()),
            duration: item.querySelector('itunes\\:duration, duration')?.textContent || null,
            link: item.querySelector('link')?.textContent || '#',
            image:
              item.querySelector('itunes\\:image')?.getAttribute('href') ||
              '/api/placeholder/200/200',
            channel,
          }));
        };

        const jreEpisodes = parseFeed(jreText, 'Joe Rogan Experience');
        const starTalkEpisodes = parseFeed(starTalkText, 'StarTalk Radio');

        const allEpisodes = [...jreEpisodes, ...starTalkEpisodes]
          .sort((a, b) => b.pubDate - a.pubDate)
          .slice(0, 10);

        setEpisodes(allEpisodes);
      } catch (err) {
        console.error('Error fetching podcast episodes:', err);
        setError('Failed to load podcast episodes. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPodcasts();
  }, []);

  const formatDate = (date) =>
    date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

  const formatDuration = (duration) => {
    if (!duration) return '';
    const parts = duration.split(':');
    if (parts.length === 2) {
      return `${parseInt(parts[0], 10)}m ${parseInt(parts[1], 10)}s`;
    } else if (parts.length === 3) {
      return `${parseInt(parts[0], 10)}h ${parseInt(parts[1], 10)}m`;
    }
    return duration;
  };

  if (isLoading) {
    return (
      <div className="mb-16">
        <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Loading Episodes...
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[...Array(4)].map((_, index) => (
            <div
              key={index}
              className={`animate-pulse flex items-center p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              }`}
            >
              <div className="w-24 h-24 bg-gray-300 rounded-lg mr-4" />
              <div className="flex-1">
                <div className="h-4 bg-gray-300 rounded w-3/4 mb-2" />
                <div className="h-3 bg-gray-300 rounded w-1/2" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <IHeartEpisodes />
    );
  }

  return (
    <div className="mb-16">
      <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Latest Episodes
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {episodes.map((episode) => (
          <a
            key={episode.id}
            href={episode.link}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center p-4 rounded-lg ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            } shadow-sm hover:shadow-md transition-all duration-200 group`}
          >
            <div className="w-24 h-24 rounded-lg overflow-hidden mr-4 relative group-hover:ring-2 ring-purple-500 transition-all">
              <img
                src={episode.image}
                alt={episode.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                <Play className="text-white" size={24} />
              </div>
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-center gap-2 mb-1">
                <span
                  className={`text-sm font-medium px-2 py-0.5 rounded ${
                    episode.channel === 'Joe Rogan Experience'
                      ? 'bg-red-100 text-red-800'
                      : 'bg-blue-100 text-blue-800'
                  }`}
                >
                  {episode.channel}
                </span>
                {episode.duration && (
                  <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    {formatDuration(episode.duration)}
                  </span>
                )}
              </div>
              <h3 className={`font-bold mb-1 truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {episode.title}
              </h3>
              <p
                className={`text-sm mb-2 line-clamp-2 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}
              >
                {episode.description}
              </p>
              <div className="flex items-center gap-2">
                <Mic className={`w-4 h-4 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`} />
                <span className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
                  {formatDate(episode.pubDate)}
                </span>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default LatestEpisodes;
