import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ArticleSection = ({ isDarkMode }) => {
  const [articles, setArticles] = React.useState([]);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [touchStart, setTouchStart] = React.useState(null);
  const [touchEnd, setTouchEnd] = React.useState(null);
  const sliderRef = React.useRef(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe && currentSlide < articles.length - 1) {
      setCurrentSlide(prev => prev + 1);
    }
    if (isRightSwipe && currentSlide > 0) {
      setCurrentSlide(prev => prev - 1);
    }
  };

  React.useEffect(() => {
    // Fetch articles from MongoDB
    const fetchArticles = async () => {
      try {
        const response = await fetch('/api/articles'); // Replace with your API endpoint
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error('Error fetching articles:', error);
        setArticles([
          {
            id: 1,
            title: "Automating a Lambda Deployment with Terraform",
            image: "http://res.cloudinary.com/dwkt7zgrd/image/upload/v1732502174/HoseaCodes/rknuoraek2czx5pk5i6l.png",
            url: "https://www.hoseacodes.com/blog/6743e306acdf7c0002b819f6"
          },
          {
            id: 2,
            title: "🚀 Scaling with AWS Lambda",
            image: "http://res.cloudinary.com/dwkt7zgrd/image/upload/v1720817444/HoseaCodes/jyrwtxegxwwgwmmbmv6r.png",
            url: "https://www.hoseacodes.com/blog/6691979449849100028cedb2"
          },
          {
            id: 3,
            title: "Level Up Your React Skills: Best Practices and Pro Tips for Clean and Efficient Coding",
            image: "http://res.cloudinary.com/dwkt7zgrd/image/upload/v1716668249/HoseaCodes/qf2gxoqacgg2qub94xrt.png",
            url: "https://www.hoseacodes.com/blog/665247664b1ff381980301c8"
          },
          {
            id: 4,
            title: "Building Reusable and Maintainable Components in React",
            image: "http://res.cloudinary.com/dwkt7zgrd/image/upload/v1716667552/HoseaCodes/gy3qobcdkb4iqke0xity.png",
            url: "https://www.hoseacodes.com/blog/665245684b1ff381980301bd"
          }
        ]);
      }
    };

    fetchArticles();
  }, []);

  const nextSlide = () => {
    if (currentSlide < articles.length - 1) {
      setCurrentSlide(prev => prev + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(prev => prev - 1);
    }
  };

  return (
    <div className="mb-16">
      <div className="p-4">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <h2 className={`text-2xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Articles
            </h2>
          </div>
          <div className="flex space-x-4">
            <button
              onClick={prevSlide}
              className={`p-2 rounded-full transition-colors
                ${isDarkMode 
                  ? 'bg-gray-800 hover:bg-gray-700 text-gray-300' 
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-600'}`}
              disabled={currentSlide === 0}
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={nextSlide}
              className={`p-2 rounded-full transition-colors
                ${isDarkMode 
                  ? 'bg-gray-800 hover:bg-gray-700 text-gray-300' 
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-600'}`}
              disabled={currentSlide === articles.length - 1}
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>

        <div className="relative overflow-hidden">
          <div 
            ref={sliderRef}
            className="flex transition-transform duration-300 ease-in-out"
            style={{ transform: `translateX(-${currentSlide * 330}px)` }}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            {articles.map((article) => (
              <div
                key={article.id}
                className={`flex-none w-[330px] mr-4 cursor-pointer 
                  ${isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-50'} 
                  rounded-lg transition-colors`}
                onClick={() => window.location.href = article.url}
              >
                <div className="flex items-center p-4">
                  <div className="flex-shrink-0">
                    <div className={`w-24 h-24 rounded-lg overflow-hidden ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
                      <img
                        src={article.image || 'https://via.placeholder.com/150'}
                        alt={article.title}
                        className="w-full h-full object-cover"
                        draggable={false}
                      />
                    </div>
                  </div>
                  <div className="flex-1 ml-3">
                    <h3 className={`font-semibold line-clamp-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {article.title}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={`border-t ${isDarkMode ? 'border-gray-800' : 'border-gray-200'} mt-6`} />
    </div>
  );
};

export default ArticleSection;