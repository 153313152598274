import React, { useState } from 'react';
import { Send, User, Mail, MessageSquare } from 'lucide-react';

const ContactSection = ({ isDarkMode }) => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    
    try {
      await fetch('https://getform.io/f/7efda21f-ca67-48f6-8a1e-723776d4ae3b', {
        method: 'POST',
        body: new FormData(e.target)
      });
      
      // Reset form
      setFormState({ name: '', email: '', message: '' });
      // You could add a success toast/message here
    } catch (error) {
      console.error('Error sending message:', error);
      // You could add an error toast/message here
    } finally {
      setIsSending(false);
    }
  };

  const handleChange = (e) => {
    setFormState(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <section 
      id="contact" 
      className={`p-8 rounded-2xl shadow-lg ${
        isDarkMode ? 'bg-[#1d1e20]' : 'bg-white'
      }`}
    >
      <div className="max-w-2xl mx-auto">
        {/* Header */}
        <div className="flex items-center gap-3 mb-8">
          <div className={`w-12 h-12 rounded-xl flex items-center justify-center ${
            isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
          }`}>
            <MessageSquare className={`w-6 h-6 ${
              isDarkMode ? 'text-purple-400' : 'text-purple-600'
            }`} />
          </div>
          <div>
            <h2 className={`text-2xl font-bold font-['Courier Prime'] ${
              isDarkMode ? 'text-white' : 'text-[#2f3237]'
            }`}>
              Let's Talk
            </h2>
            <p className={`mt-1 ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              Have a question or proposal? Drop me a message!
            </p>
          </div>
        </div>

        {/* Form */}
        <form className="space-y-6" onSubmit={handleSubmit}>
          {/* Name Input */}
          <div className="relative">
            <User className={`absolute left-3 top-3.5 w-5 h-5 ${
              isDarkMode ? 'text-gray-500' : 'text-gray-400'
            }`} />
            <input
              type="text"
              name="name"
              value={formState.name}
              onChange={handleChange}
              placeholder="Name"
              required
              className={`w-full p-3 pl-10 rounded-lg border transition-colors duration-200 ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-500 focus:border-purple-500' 
                  : 'bg-[#f8f9fc] border-[#dee3ed] text-gray-900 placeholder-gray-500 focus:border-purple-500'
              } focus:outline-none focus:ring-2 focus:ring-purple-500/20`}
            />
          </div>

          {/* Email Input */}
          <div className="relative">
            <Mail className={`absolute left-3 top-3.5 w-5 h-5 ${
              isDarkMode ? 'text-gray-500' : 'text-gray-400'
            }`} />
            <input
              type="email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className={`w-full p-3 pl-10 rounded-lg border transition-colors duration-200 ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-500 focus:border-purple-500' 
                  : 'bg-[#f8f9fc] border-[#dee3ed] text-gray-900 placeholder-gray-500 focus:border-purple-500'
              } focus:outline-none focus:ring-2 focus:ring-purple-500/20`}
            />
          </div>

          {/* Message Textarea */}
          <div className="relative">
            <textarea
              name="message"
              value={formState.message}
              onChange={handleChange}
              placeholder="Your Message"
              required
              rows="4"
              className={`w-full p-3 rounded-lg border transition-colors duration-200 ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-500 focus:border-purple-500' 
                  : 'bg-[#f8f9fc] border-[#dee3ed] text-gray-900 placeholder-gray-500 focus:border-purple-500'
              } focus:outline-none focus:ring-2 focus:ring-purple-500/20`}
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isSending}
            className={`w-full px-6 py-3 rounded-lg flex items-center justify-center gap-2 font-medium transition-all duration-200 ${
              isDarkMode 
                ? 'bg-[#2f3237] text-white hover:bg-gray-700' 
                : 'bg-[#5e636e] text-white hover:bg-gray-600'
            } disabled:opacity-70 disabled:cursor-not-allowed`}
          >
            <Send className={`w-5 h-5 ${isSending ? 'animate-pulse' : ''}`} />
            {isSending ? 'Sending...' : 'Send Message'}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;