import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const YoutubeSlider = ({ isDarkMode }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const videos = [
    {
      id: 1,
      title: "UNLOCK THE BEST YOU | Motivational Video | Morning Affirmations",
      videoId: "uc3mRVjZM_I"
    },
    {
      id: 2,
      title: "BO6 Game 2",
      videoId: "SUXYhnVGreo"
    },
    {
      id: 3,
      title: "Best Gaming Moments",
      videoId: "example3"
    },
    {
      id: 4,
      title: "Gaming Room Setup",
      videoId: "example4"
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => 
      prev === videos.length - 1 ? 0 : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => 
      prev === 0 ? videos.length - 1 : prev - 1
    );
  };

  const getEmbedUrl = (videoId) => {
    return `https://www.youtube.com/embed/${videoId}?si=Z_UxDG2yIkPfq-zf`;
  };

  return (
    <div className="mb-16">
      <div className="flex items-center justify-between mb-6">
        <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          YouTube
        </h2>
        <div className="flex space-x-4">
          <button
            onClick={prevSlide}
            className={`p-2 rounded-full transition-colors
              ${isDarkMode 
                ? 'bg-gray-800 hover:bg-gray-700 text-gray-300' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-600'}`}
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={nextSlide}
            className={`p-2 rounded-full transition-colors
              ${isDarkMode 
                ? 'bg-gray-800 hover:bg-gray-700 text-gray-300' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-600'}`}
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>

      <div className="relative overflow-hidden rounded-xl">
        <div 
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {videos.map((video) => (
            <div 
              key={video.id}
              className="flex-none w-full"
            >
              <div className={`relative aspect-video ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
                <iframe
                  width="100%"
                  height="100%"
                  src={getEmbedUrl(video.videoId)}
                  title={video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="absolute inset-0"
                />
              </div>
              <div className={`mt-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                <h3 className="text-lg font-medium">
                  {video.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YoutubeSlider;