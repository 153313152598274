import React, { useState } from 'react'
import { HiOutlineDownload } from "react-icons/hi";

const AppShowcase = ({ app, onClose, platforms }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAPIModal, setShowAPIModal] = useState(false);
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      reasons: {
        research: false,
        commercialUse: false,
        personalProject: false,
        startup: false,
        other: false
      },
      otherReason: ''
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };

    const handleReasonChange = (reason) => {
      setFormData(prev => ({
        ...prev,
        reasons: {
          ...prev.reasons,
          [reason]: !prev.reasons[reason]
        }
      }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      console.log('API Access Request:', formData);
      setShowAPIModal(false);
    };

    // Prepare showcase data
    const showcaseData = {
      active: app.active,
      title: app.name,
      url: app.url,
      description: app.description || `A ${app.type} ${app.category} hosted on ${app.platform}`,
      type: app.type?.charAt(0).toUpperCase() + app.type?.slice(1) || app.category?.charAt(0).toUpperCase() + app.category?.slice(1),
      rating: app.rating || 4.5,
      technologies: app.technologies || [app.platform],
      logo: app.logo,
      platforms: [
        { 
          name: app.category === 'service' ? 'Learn More' : 'View Demo', 
          link: app.url, 
          color: 'bg-blue-500' 
        },
        { 
          name: 'API Access', 
          color: 'bg-purple-500', 
          onClick: () => setShowAPIModal(true) 
        }
      ]
    };

    // Modal Component
    const Modal = ({ isOpen, onClose, title, children }) => {
      if (!isOpen) return null;
      
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[60] flex items-center justify-center p-4" onClick={(e) => e.stopPropagation()}>
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{title}</h2>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700">✕</button>
            </div>
            {children}
          </div>
        </div>
      );
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4" onClick={onClose}>
        <div className="w-full max-w-4xl bg-white rounded-xl shadow-lg" onClick={(e) => e.stopPropagation()}>
          <div className="relative">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 z-10 p-2 bg-gray-800/50 hover:bg-gray-800/70 text-white rounded-lg"
            >
              ✕
            </button>

            {/* Header */}
            <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-6 rounded-t-xl text-center">
              <h1 className="text-3xl font-bold text-white mb-2">{showcaseData.type}</h1>
              <h2 className="text-2xl font-semibold text-white mb-4">{showcaseData.title}</h2>

              <div className="flex justify-center mb-4">
                <img
                  src={showcaseData.logo}
                  alt={`${showcaseData.title} Logo`}
                  className="w-32 h-32 rounded-2xl shadow-md bg-white object-contain"
                />
              </div>

              <div className="flex justify-center items-center space-x-4 text-white mb-4">
                <div className="flex items-center">
                  <span className="text-yellow-300 mr-1">★</span>
                  <span>{showcaseData.rating}</span>
                </div>
                <button
                  onClick={() => setShowInfoModal(true)}
                  className="px-4 py-2 bg-white/20 hover:bg-white/30 rounded-md transition-colors"
                >
                  Learn More
                </button>
              </div>

              <div className="flex justify-center gap-2 flex-wrap">
                {showcaseData.technologies.map((tech, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-white/20 rounded-full text-sm text-white"
                  >
                    {tech}
                  </span>
                ))}
              </div>
            </div>

            {/* Content */}
            {app.hasIframe && (
              <div className={`relative ${isFullscreen ? 'h-[36rem]' : 'h-0'}`}>
                <div className="absolute inset-0">
                  <iframe
                    src={app.url}
                    className="w-full h-full"
                    title={app.name}
                    frameBorder="0"
                    loading="lazy"
                    allowFullScreen
                  />
                </div>
                <button
                  onClick={() => setIsFullscreen(!isFullscreen)}
                  className="absolute top-4 right-4 p-2 rounded-lg bg-gray-800/50 hover:bg-gray-800/70 text-white transition-colors"
                >
                  {isFullscreen ? '↙' : '↗'}
                </button>
              </div>
            )}

            <div className="p-6">
              <p className="text-gray-600 mb-6">{showcaseData.description}</p>
              
              <div className="grid grid-cols-2 gap-4">
                {showcaseData.active === true && showcaseData.platforms.map((platform, index) => (
                  <a
                    key={index}
                    href={platform.link}
                    onClick={platform.onClick}
                    className={`${platform.color} text-white py-3 px-4 rounded-lg flex items-center justify-center hover:opacity-90 transition duration-300 cursor-pointer`}
                  >
                    <HiOutlineDownload size={20} />
                    &nbsp;
                    {platform.name}
                  </a>
                ))}
              </div>
            </div>

            {/* Footer */}
            <div className="p-4 border-t border-gray-200">
              <div className="flex items-center space-x-2 text-gray-600">
                <span>{platforms[app.platform]?.icon}</span>
                <span>Hosted on {app.platform.charAt(0).toUpperCase() + app.platform.slice(1)}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        <Modal
          isOpen={showInfoModal}
          onClose={() => setShowInfoModal(false)}
          title={showcaseData.title}
        >
          <p className="text-gray-600 mb-4">{showcaseData.description}</p>
          <div>
            <h4 className="font-semibold mb-2">Technologies Used</h4>
            <div className="flex flex-wrap gap-2">
              {showcaseData.technologies.map((tech, index) => (
                <span
                  key={index}
                  className="px-3 py-1 bg-gray-100 rounded-full text-sm"
                >
                  {tech}
                </span>
              ))}
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showAPIModal}
          onClose={() => setShowAPIModal(false)}
          title="Request API Access"
        >
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your full name"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your email address"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Reasons for Access
              </label>
              <div className="space-y-2">
                {Object.keys(formData.reasons).map((reason) => (
                  reason !== 'other' && (
                    <div key={reason} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id={reason}
                        checked={formData.reasons[reason]}
                        onChange={() => handleReasonChange(reason)}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label htmlFor={reason} className="text-sm text-gray-700">
                        {reason.charAt(0).toUpperCase() + reason.slice(1).replace(/([A-Z])/g, ' $1')}
                      </label>
                    </div>
                  )
                ))}
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="other"
                    checked={formData.reasons.other}
                    onChange={() => handleReasonChange('other')}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label htmlFor="other" className="text-sm text-gray-700">
                    Other
                  </label>
                </div>
                {formData.reasons.other && (
                  <input
                    type="text"
                    name="otherReason"
                    value={formData.otherReason}
                    onChange={handleInputChange}
                    placeholder="Please specify other reason"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-blue-500 mt-2"
                    />
                  )}
                </div>
              </div>
  
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
              >
                Submit API Access Request
              </button>
            </form>
          </Modal>
        </div>
      );
};
  
export default AppShowcase;