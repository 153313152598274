import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Star, Users, Bath, Bed, MapPin } from 'lucide-react';

const CombinedSlider = ({ isDarkMode }) => {
  const [activeTab, setActiveTab] = useState('fiverr');
  const [currentImageIndexes, setCurrentImageIndexes] = useState({});

  const properties = [
    {
      id: 1,
      name: "Location Speaks For Itself!",
      location: "Dallas, TX",
      rating: 4.9,
      reviews: 156,
      price: 110,
      images: [
        "https://a0.muscache.com/im/pictures/miso/Hosting-1287255020364074385/original/987a6920-4a4d-48d9-b3a8-ba7d237e1600.png?im_w=1440&im_format=avif",
        "https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTI4NzI1NTAyMDM2NDA3NDM4NQ%3D%3D/original/7ada53f0-1795-43b8-88d0-95a0a7665661.jpeg?im_w=480&im_format=avif",
        "https://a0.muscache.com/im/pictures/miso/Hosting-1287255020364074385/original/95dd921f-cd4a-4414-a54a-a86e02e00152.png?im_w=480&im_format=avif"
      ],
      beds: 1,
      baths: 1,
      guests: 3,
      description: "An urban sanctuary in the city's most dynamic neighborhood.",
      url: "https://www.airbnb.com/slink/fwPwakkJ"
    },
    // {
    //   id: 2,
    //   name: "Streamer's Dream Loft",
    //   location: "Los Angeles, CA",
    //   rating: 4.8,
    //   reviews: 124,
    //   price: 249,
    //   images: ["/api/placeholder/600/400", "/api/placeholder/600/400", "/api/placeholder/600/400"],
    //   beds: 2,
    //   baths: 2,
    //   guests: 4,
    //   description: "Perfect for content creators with professional streaming setup"
    // }
  ];

  const gigs = [
    {
      id: 1,
      title: "I will create a web application with react",
      rating: 5.0,
      reviews: 24,
      price: 200,
      images: ["https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/384707281/original/c61314cee17e6354d209084512c741a2d5da826a/create-and-or-web-appliations-with-react.png"],
      description: "Develop high-quality and scalable web applications using React.",
      url: "https://www.fiverr.com/ambitiousconcep/create-and-or-web-appliations-with-react"
    },
    {
      id: 2,
      title: "I will build mobile apps in IOS and andriod with react native",
      rating: 4.8,
      reviews: 18,
      price: 300,
      images: ["https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs2/384755770/original/83ba995fadc31aed3e83c2ffe5a58cacba4a58cb/build-mobile-apps-in-ios-and-andriod-with-react-native.jpg"],
      description: "Develop cross-platform mobile applications for iOS and Android using React Native.",
      url: "https://www.fiverr.com/ambitiousconcep/build-mobile-apps-in-ios-and-andriod-with-react-native"
    }
  ];

  const nextImage = (itemId) => {
    alert('Next Image');
    setCurrentImageIndexes(prev => ({
      ...prev,
      [itemId]: (prev[itemId] + 1) % 3
    }));
  };

  const prevImage = (itemId) => {
    setCurrentImageIndexes(prev => ({
      ...prev,
      [itemId]: (prev[itemId] - 1 + 3) % 3
    }));
  };

  return (
    <div className="mb-16">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center mb-8">
          <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Featured Listings
          </h2>
          <div className="flex space-x-4">
            <button
              onClick={() => setActiveTab('airbnb')}
              className={`px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'airbnb'
                  ? 'bg-[#23292f] text-white'
                  : isDarkMode
                  ? 'bg-gray-700 text-gray-300'
                  : 'bg-gray-200 text-gray-700'
              }`}
            >
              Airbnb
            </button>
            <button
              onClick={() => setActiveTab('fiverr')}
              className={`px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'fiverr'
                  ? 'bg-[#23292f] text-white'
                  : isDarkMode
                  ? 'bg-gray-700 text-gray-300'
                  : 'bg-gray-200 text-gray-700'
              }`}
            >
              Fiverr
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {activeTab === 'airbnb' ? (
            properties.map((property) => (
              <div
                key={property.id}
                className={`rounded-xl overflow-hidden ${
                  isDarkMode ? 'bg-gray-800' : 'bg-white'
                } shadow-lg transition-transform hover:scale-[1.02]`}
              >
                <div className="relative aspect-[4/3]">
                  <img
                    src={property.images[currentImageIndexes[property.id] || 0]}
                    alt={property.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                  
                  <button
                    onClick={() => prevImage(property.id)}
                    className="absolute left-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white"
                  >
                    <ChevronLeft size={20} className="text-gray-900" />
                  </button>
                  <button
                    onClick={() => nextImage(property.id)}
                    className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 hover:bg-white"
                  >
                    <ChevronRight size={20} className="text-gray-900" />
                  </button>
                </div>

                <div className="p-4">
                  <div className="flex justify-between items-start mb-2">
                    <h3 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {property.name}
                    </h3>
                    <div className="flex items-center">
                      <Star size={16} className="text-yellow-400" />
                      <span className={`ml-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        {property.rating}
                      </span>
                    </div>
                  </div>
                  
                  <div className="flex items-center mb-3">
                    <MapPin size={16} className={isDarkMode ? 'text-gray-400' : 'text-gray-600'} />
                    <span className={`ml-1 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        {property.location}
                    </span>
                  </div>

                  <div className="flex items-center gap-4 mb-3">
                  <div className="flex items-center">
                    <Users size={16} className={isDarkMode ? 'text-gray-400' : 'text-gray-600'} />
                    <span className={`ml-1 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {property.guests} guests
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Bed size={16} className={isDarkMode ? 'text-gray-400' : 'text-gray-600'} />
                    <span className={`ml-1 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {property.beds} beds
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Bath size={16} className={isDarkMode ? 'text-gray-400' : 'text-gray-600'} />
                    <span className={`ml-1 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {property.baths} baths
                    </span>
                  </div>
                </div>

                <div className={`text-sm mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  {property.description}
                </div>

                  <div className="flex items-baseline justify-between">
                    <div>
                      <span className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        ${property.price}
                      </span>
                      <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}> /night</span>
                    </div>
                    <button className="px-4 py-2 bg-[#23292f] text-white rounded-lg hover:bg-purple-700">
                      <a href={property.url} target="_blank" rel="noopener noreferrer">
                        Book Now
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            gigs.map((gig) => (
              <div
                key={gig.id}
                className={`rounded-lg overflow-hidden ${
                  isDarkMode ? 'bg-gray-800' : 'bg-white'
                } shadow-lg transition-transform hover:scale-105`}
              >
                <div className="relative aspect-[4/3]">
                  <img
                    src={gig.images[currentImageIndexes[gig.id] || 0]}
                    alt={gig.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />

                  <button
                    onClick={() => prevImage(gig.id)}
                    className="absolute left-2 top-1/2 -translate-y-1/2 p-2 bg-white/80 rounded-full hover:bg-white"
                  >
                    <ChevronLeft size={20} />
                  </button>
                  <button
                    onClick={() => nextImage(gig.id)}
                    className="absolute right-2 top-1/2 -translate-y-1/2 p-2 bg-white/80 rounded-full hover:bg-white"
                  >
                    <ChevronRight size={20} />
                  </button>
                </div>

                <div className="p-4">
                  <h3 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {gig.title}
                  </h3>

                  <div className="flex items-center my-2">
                    <Star size={16} className="text-yellow-400" />
                    <span className={`ml-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {gig.rating}
                    </span>
                    <span className={`ml-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      ({gig.reviews} reviews)
                    </span>
                  </div>

                  <p className={`text-sm mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {gig.description}
                  </p>

                  <div className="flex items-baseline justify-between">
                    <div>
                      <span className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        ${gig.price}
                      </span>
                    </div>
                    <button className="px-4 py-2 bg-[#23292f] text-white rounded-lg hover:bg-purple-700">
                      <a href={gig.url} target="_blank" rel="noopener noreferrer">
                        View Gig
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CombinedSlider;