import React from 'react';
import { Award, Code, Users, Building2, Briefcase, Server, BookOpen, GitBranch, Globe } from 'lucide-react';
import { FaAws } from "react-icons/fa";

export default function FeaturedContent({ isDarkMode }) {
  const achievements = [
    {
      id: 1,
      title: "AWS Certified Cloud Practitioner ",
      description: "Demonstrated knowledge of basic AWS services, use cases, billing and pricing models, security concepts, and cloud architecture principles.",
      icon: FaAws,
      stats: "Certified",
      achieved: true,
      completed: "August 2024"
    },
    {
      id: 2,
      title: "AWS Developers Associate",
      description: "Knowledge in developing, optimizing, packaging, and deploying applications, using CI/CD workflows, and identifying and resolving application issues.",
      icon: FaAws,
      stats: "April 2024",
      achieved: false
    }
    // {
    //   id: 1,
    //   title: "Full Stack Development",
    //   description: "Built and deployed over 40+ web applications using React, Node.js, Python, and various cloud platforms",
    //   icon: Code,
    //   stats: "40+ Apps"
    // },
    // {
    //   id: 2,
    //   title: "Technical Leadership",
    //   description: "Led development teams and mentored junior developers across multiple successful projects",
    //   icon: Users,
    //   stats: "10+ Teams"
    // },
    // {
    //   id: 3,
    //   title: "Business Growth",
    //   description: "Founded and scaled Ambitious Concepts, delivering innovative solutions to diverse clients",
    //   icon: Building2,
    //   stats: "2+ Years"
    // },
    // {
    //   id: 4,
    //   title: "Client Success",
    //   description: "Successfully delivered projects for multiple clients with high satisfaction rates",
    //   icon: Briefcase,
    //   stats: "15+ Clients"
    // },
    // {
    //   id: 5,
    //   title: "Infrastructure Management",
    //   description: "Designed and managed cloud infrastructure on AWS, Vercel, and other platforms",
    //   icon: Server,
    //   stats: "20+ Services"
    // },
    // {
    //   id: 6,
    //   title: "Technical Writing",
    //   description: "Published technical articles and documentation reaching thousands of developers",
    //   icon: BookOpen,
    //   stats: "50+ Articles"
    // },
    // {
    //   id: 7,
    //   title: "Open Source",
    //   description: "Contributed to open source projects and maintained personal packages on NPM",
    //   icon: GitBranch,
    //   stats: "100+ Commits"
    // },
    // {
    //   id: 8,
    //   title: "Global Impact",
    //   description: "Created solutions used by clients and users across multiple countries",
    //   icon: Globe,
    //   stats: "5+ Countries"
    // },
    // {
    //   id: 9,
    //   title: "Professional Recognition",
    //   description: "Received certifications and recognition for technical excellence",
    //   icon: Award,
    //   stats: "8+ Certs"
    // }
  ];

  return (
    <>
      <h2 className={`text-3xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Recent Achievements
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-16">
        {achievements.map((item) => {
          const IconComponent = item.icon;
          return (
            <div
              key={item.id}
              className={`rounded-lg p-6 ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              } shadow-sm hover:shadow-md transition-all duration-300 group ${
                !item.achieved ? 'border-2 border-dashed border-purple-400/50' : ''
              }`}
            >
              <div className="flex items-center justify-between mb-4">
                <div className={`rounded-full p-3 w-12 h-12 flex items-center justify-center 
                  ${isDarkMode ? 'bg-gray-700' : 'bg-purple-100'}
                  group-hover:scale-110 transition-transform duration-300`}>
                  <IconComponent fontSize={32} className={`${isDarkMode ? 'text-purple-400' : 'text-purple-600'}`} />
                </div>
                <span className={`text-sm font-semibold px-3 py-1 rounded-full 
                  ${!item.achieved 
                    ? isDarkMode 
                      ? 'bg-purple-400/20 text-purple-400' 
                      : 'bg-purple-100/50 text-purple-600'
                    : isDarkMode 
                      ? 'bg-gray-700 text-purple-400' 
                      : 'bg-purple-100 text-purple-600'
                  }`}>
                  {item.stats}
                </span>
              </div>
              <h3 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}
                group-hover:text-purple-500 transition-colors duration-300 flex items-center gap-2`}>
                {item.title}
                {!item.achieved && 
                  <span className={`text-sm font-normal ${isDarkMode ? 'text-purple-400' : 'text-purple-600'}`}>
                    (Target)
                  </span>
                }
                {item.completed && 
                  <span className={`text-sm font-normal ${isDarkMode ? 'text-purple-400' : 'text-purple-600'}`}>
                    - {item.completed}
                  </span>
                }
              </h3>
              <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
}