import React from 'react'

export default function IHeartEpisodes({ isDarkMode }) {
  return (
    <div className="mb-16">
      <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Latest Episodes
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <iframe allow="autoplay" width="100%" height="300" src="https://www.iheart.com/podcast/81-startalk-radio-20633896/episode/food-science-and-culture-with-anthony-243189025/?embed=true" frameborder="0"></iframe>
        <iframe allow="autoplay" width="100%" height="300" src="https://www.iheart.com/podcast/81-startalk-radio-20633896/episode/a-cosmic-conversation-with-kip-thorne-242160724/?embed=true" frameborder="0"></iframe>
        <iframe allow="autoplay" width="100%" height="300" src="https://www.iheart.com/podcast/1057-the-joe-rogan-experience-27959911/episode/2236-protect-our-parks-13-243036716/?embed=true" frameborder="0"></iframe>
        <iframe allow="autoplay" width="100%" height="300" src="https://www.iheart.com/podcast/1057-the-joe-rogan-experience-27959911/episode/2223-elon-musk-234829204/?embed=true" frameborder="0"></iframe>
      </div>
    </div>
  )
}
