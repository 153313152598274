import React from 'react'

export default function Hero({ isDarkMode }) {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between mb-16">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <h1 className={`text-4xl md:text-6xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Welcome to HoseaCodes
            </h1>
            <p className={`text-lg mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Your ultimate destination for engineering, BO6 gaming content, fitness,  streams, and community.
            </p>
            <button className="bg-[#23292f] text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition-colors">
              Join Now
            </button>
          </div>
          <div className="w-full md:w-1/2">
            <div className={`aspect-video rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`}>
              <img src="https://images.unsplash.com/photo-1619190324856-af3f6eb55601?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Gaming" className="w-full h-full object-cover rounded-lg" />
            </div>
        </div>
    </div>
  )
}
