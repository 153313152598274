import GamingWebsite from './components/GamingWebsite';

function App() {
  return (
    <div>
      <GamingWebsite />
    </div>
  );
}

export default App;