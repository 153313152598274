import React from 'react';
import { Coffee } from 'lucide-react';

const DonationCard = ({ isDarkMode }) => {
  return (
    <div className={`mb-16 p-6 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-sm`}>
      <div className="flex flex-col md:flex-row items-center justify-evenly gap-8">
        {/* QR Code Section */}
        <div className="flex flex-col items-center space-y-4">
          <h3 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Scan to Support
          </h3>
          <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-white' : 'bg-gray-100'}`}>
            {/* Replace with actual QR code - using placeholder for now */}
            <img 
              src="bmc_qr.png" 
              alt="Donation QR Code"
              className="w-48 h-48"
            />
          </div>
        </div>

        {/* Buy Me a Coffee Section */}
        <div className="flex flex-col items-center md:items-start space-y-4">
          <h3 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Support My Work
          </h3>
          <p className={`text-center md:text-left max-w-md ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            If you enjoy my content and want to support my work, consider buying me a coffee! Your support helps me create more awesome content & applications.
          </p>
          <a 
            href="https://buymeacoffee.com/mrdhoseaa"
            target="_blank"
            rel="noopener noreferrer"
            className={`inline-flex items-center space-x-2 px-6 py-3 rounded-lg 
              ${isDarkMode ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-yellow-400 hover:bg-yellow-500'}
              transition-colors duration-200`}
          >
            <Coffee size={24} className="text-gray-900" />
            <span className="font-medium text-gray-900">Buy Me a Coffee</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DonationCard;