import React, { useState, useMemo } from 'react';
import { Timer, Bell } from 'lucide-react';
import { ComingSoonModal } from './ComingSoonModal';
import AppShowcase from './AppShowcase';
import { apps } from '../data/index';

const platforms = {
  vercel: { color: 'black', icon: '⟁' },
  aws: { color: 'orange', icon: '☁️' },
  npm: { color: 'red', icon: '📦' },
  github: { color: 'purple', icon: '🐙' },
  hostinger: { color: 'blue', icon: '🕸️' },
  render: { color: 'green', icon: '⚫️' },
  heroku: { color: 'purple', icon: '🟣' },
  squarespace: { color: 'black', icon: '🌐' },
  fiverr: { color: 'green', icon: '💼' }
};

const CombinedPortfolio = ({ isDarkMode }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [showShowcase, setShowShowcase] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [selectedComingSoonApp, setSelectedComingSoonApp] = useState(null);
  const [comingSoonEmail, setComingSoonEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const itemsPerPage = 4;

  const filters = [
    { id: 'all', label: 'All' },
    { id: 'app', label: 'Apps' },
    { id: 'domain', label: 'Domains' },
    { id: 'service', label: 'Services' },
    { id: 'package', label: 'Packages' },
    { id: 'website', label: 'Websites' }
  ];
  
  const renderAppCard = (app, index) => {
    const PlatformIcon = platforms[app.platform]?.icon;
    const isComingSoon = app.releaseDate && !app.active;
    
    return (
      <div
        key={index}
        className={`p-6 rounded-xl ${
          isDarkMode ? 'bg-gray-800' : 'bg-white'
        } shadow-lg hover:shadow-xl transition-shadow`}
      >
        <div className="flex justify-between items-start mb-4">
          <h3 className={`font-bold text-lg ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            {app.name}
          </h3>
          <span className="text-lg">{PlatformIcon}</span>
        </div>
        
        {app.description && (
          <p className={`text-sm mb-4 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            {app.description}
          </p>
        )}
        
        <div className="flex space-x-4">
          {app.url && app.active && (
            <a
              href={app.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-purple-500 hover:text-purple-600 transition-colors"
            >
              <span>↗</span>
              <span>Visit</span>
            </a>
          )}
          {isComingSoon && (
            <button
              onClick={() => {
                setSelectedComingSoonApp(app);
                setShowComingSoon(true);
              }}
              className="flex items-center gap-2 text-yellow-500 hover:text-yellow-600 transition-colors"
            >
              <Timer size={16} />
              <span>Coming Soon</span>
            </button>
          )}
          <button
            onClick={() => {
              setSelectedApp(app);
              setShowShowcase(true);
            }}
            className="flex items-center gap-2 text-blue-500 hover:text-blue-600 transition-colors"
          >
            <span>ℹ</span>
            <span>Details</span>
          </button>
        </div>
      </div>
    );
  };
  
    const filteredApps = useMemo(() => {
        return apps
          .filter(app => {
            const matchesSearch = app.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFilter = activeFilter === 'all' || app.category === activeFilter;
            return matchesSearch && matchesFilter;
          })
          .sort((a, b) => a.name.localeCompare(b.name));
      }, [searchTerm, activeFilter]);
  
    const totalPages = Math.ceil(filteredApps.length / itemsPerPage);
    
    const paginatedApps = useMemo(() => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      return filteredApps.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredApps, currentPage]);
  
    const goToPage = (page) => {
      setCurrentPage(Math.max(1, Math.min(page, totalPages)));
    };
  
    const renderPagination = () => {
      const maxVisiblePages = 5;
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
  
      if (endPage - startPage + 1 < maxVisiblePages) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }
  
      return (
        <div className="flex items-center justify-center mt-8 gap-2">
          <button
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
            className={`p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                : 'bg-white text-gray-700 hover:bg-gray-100'
            } disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            ←
          </button>
  
          {startPage > 1 && (
            <>
              <button
                onClick={() => goToPage(1)}
                className={`w-10 h-10 rounded-lg ${
                  currentPage === 1
                    ? isDarkMode 
                      ? 'bg-[#23292f] text-white' 
                      : 'bg-purple-500 text-white'
                    : isDarkMode
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                      : 'bg-white text-gray-700 hover:bg-gray-100'
                }`}
              >
                1
              </button>
              {startPage > 2 && (
                <span className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>...</span>
              )}
            </>
          )}
  
          {Array.from({ length: endPage - startPage + 1 }).map((_, i) => (
            <button
              key={startPage + i}
              onClick={() => goToPage(startPage + i)}
              className={`w-10 h-10 rounded-lg transition-colors ${
                currentPage === startPage + i
                  ? isDarkMode 
                    ? 'bg-[#23292f] text-white' 
                    : 'bg-purple-500 text-white'
                  : isDarkMode
                    ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
              }`}
            >
              {startPage + i}
            </button>
          ))}
  
          {endPage < totalPages && (
            <>
              {endPage < totalPages - 1 && (
                <span className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>...</span>
              )}
              <button
                onClick={() => goToPage(totalPages)}
                className={`w-10 h-10 rounded-lg ${
                  currentPage === totalPages
                    ? isDarkMode 
                      ? 'bg-[#23292f] text-white' 
                      : 'bg-purple-500 text-white'
                    : isDarkMode
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                      : 'bg-white text-gray-700 hover:bg-gray-100'
                }`}
              >
                {totalPages}
              </button>
            </>
          )}
  
          <button
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                : 'bg-white text-gray-700 hover:bg-gray-100'
            } disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            →
          </button>
        </div>
      );
    };
  
    return (
      <div className={`max-w-7xl mx-auto p-6 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        
        <div className="mb-8">
          <h2 className={`text-3xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            My Applications
          </h2>
          
          <div className="flex flex-col md:flex-row gap-4 items-center">
            <div className="relative flex-1">
              <span 
                className={`absolute left-3 top-1/2 -translate-y-1/2 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}
              >
                🔍
              </span>
              <input
                type="text"
                placeholder="Search applications..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700 text-white' 
                    : 'bg-white border-gray-200 text-gray-900'
                } focus:outline-none focus:ring-2 focus:ring-purple-500`}
              />
            </div>
            
            <div className="flex gap-2 flex-wrap">
              {filters.map(filter => (
                <button
                  key={filter.id}
                  onClick={() => setActiveFilter(filter.id)}
                  className={`px-4 py-2 rounded-lg transition-colors ${
                    activeFilter === filter.id
                      ? isDarkMode 
                        ? 'bg-[#23292f] text-white' 
                        : 'bg-purple-500 text-white'
                      : isDarkMode
                        ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                        : 'bg-white text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  {filter.label}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {paginatedApps.map((app, index) => renderAppCard(app, index))}
        </div>
  
        {renderPagination()}
  
        <div className={`text-center mt-4 ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, filteredApps.length)} of {filteredApps.length} results
        </div>
  
        {/* Showcase Modal */}
        {showShowcase && selectedApp && (
          <AppShowcase
            app={selectedApp} 
            onClose={() => {
              setShowShowcase(false);
              setSelectedApp(null);
            }}
            platforms={platforms}
          />
        )}
        {showComingSoon && selectedComingSoonApp && (
        <ComingSoonModal
          app={selectedComingSoonApp} 
          onClose={() => {
            setShowComingSoon(false);
            setSelectedComingSoonApp(null);
          }}
          isDarkMode={isDarkMode}
          isSubscribed={isSubscribed}
          comingSoonEmail={comingSoonEmail}
          setComingSoonEmail={setComingSoonEmail}
          setIsSubscribed={setIsSubscribed}
        />
      )}
      </div>
    );
  };
  
  export default CombinedPortfolio;