export const apps = [
    // Hostinger Domains
    {
      name: "Ambitious Concepts Domain",
    //   url: "http://ambitiousconcepts.io/",
      active: false,
      category: "domain",
      platform: "hostinger",
      type: "business",
      logo: "hostinger.png",
      description: "Domain for Ambitious Concepts"
    },
    {
      name: "Hosea Fitness",
      url: "https://hoseafitness.com/",
      active: true,
      category: "website",
      platform: "hostinger",
      type: "fitness",
      logo: "fitness.png",
      description: "Fitness and wellness platform"
    },
    {
      name: "Insightlytics Domain",
    //   url: "https://insightlytics.app/",
      active: false,
      category: "domain",
      platform: "hostinger",
      type: "analytics",
      logo: "logo.png",
      description: "Domain for Analytics platform"
    },
    // Square Space Domains (mr.dhosea)
    {
        name: "Career Connect",
        // url: "https://career-connect.io",
        active: false,
        category: "service",
        platform: "squarespace",
        type: "career",
        logo: "https://camo.githubusercontent.com/a8179fb2526a240f931232ce377fb19460320dd1423c8c3d0bbd46115282bc65/68747470733a2f2f692e696d6775722e636f6d2f4d6336426456302e706e67",
        description: "Career development platform",
        technologies: ["EJS Templates", "Javascript", "CSS"]
    },
    {
        name: "Hosea Designs Domain",
        // url: "https://dhoseadesigns.com",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "portfolio",
        logo: "logo.png",
        description: "Domain for design portfolio"
    },
    {
        name: "Dominique Hosea Portfolio",
        url: "https://dominiquehosea.com",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "portfolio",
        logo: "logo.png",
        description: "Technical portfolio website",
        technologies: ["EJS", "Javascript", "CSS"]
    },
    {
        name: "Emprie Fitness Domain",
        // url: "https://empriefitness.com",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "fitness",
        logo: "fitness.png",
        description: "Domain for fitness and wellness platform"
    },
    {
        name: "Hosea Codes Blog",
        url: "https://hoseacodes.com",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "portfolio",
        logo: "logo.png",
        description: "Technical blog and portfolio",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "Identity Flows Domain",
        // url: "https://identityflows.com",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "domain",
        logo: "logo.png",
        description: "Domain for identity service"
    },
    {
        name: "Merge Immersive",
        url: "https://mergeimmersive.com",
        active: true,
        category: "service",
        platform: "squarespace",
        type: "technology",
        logo: "logo.png",
        description: "Bootcamp social network",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "Professional Blog Portfolio",
        url: "https://portfolio.dominiquehosea.com",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "portfolio",
        logo: "logo.png",
        description: "Technical professional blog and portfolio",
        technologies: ["Squarespace", "Javascript", "CSS"]
    },
    {
        name: "Sneaker API",
        url: "https://www.sneakerapi.io",
        active: true,
        category: "service",
        platform: "squarespace",
        type: "api",
        logo: "sneakerapi.png",
        description: "Sneaker information API",
        technologies: ["Express", "Javascript", "CSS"]
    },
    {
        name: "Social Ring",
        url: "https://www.social-ring.com",
        active: true,
        category: "app",
        platform: "squarespace",
        type: "social",
        logo: "social-logo.png",
        description: "Social network tool website",
        technologies: ["Html", "Javascript", "CSS"],
        hasIframe: true
    },
    {
        name: "Storm Gate API",
        // url: "https://storm-gate.net",
        active: false,
        category: "service",
        platform: "squarespace",
        type: "infrastructure",
        logo: "storm.png",
        description: "Terraform, IAM, Security Group, Route53, EC2",
        technologies: ["Express", "Javascript", "EC2"]
    },
    {
        name: "UI Heat",
        // url: "https://uiheat.com",
        active: false,
        category: "package",
        platform: "squarespace",
        type: "design",
        logo: "uiheat.png",
        description: "UI design tool",
        technologies: ["React", "Javascript", "CSS"]
    },
    
    // Square Space Domains (ambitious concepts)
    {
        name: "Ambitious Concept",
        url: "https://ambitiousconcept.com",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "business",
        description: "Ambitious Concepts is a full service web design, UX/UI, digital development and marketing agency.",
        logo: "ac.png",
        technologies: ["Wordpress", "Javascript", "CSS"]
    },
    {
        name: "Ambitious Concepts Labs Domain",
        // url: "https://ambitiousconceptslabs.com",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "technology",
        description: "Ambitious Concepts Labs is a technology incubator and accelerator.",
        logo: "ac.png",
    },
    {
        name: "Automated Delights",
        url: "https://automateddelights.com",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "automation",
        description: "Automated Delights is a home automation and smart home technology company.",
        logo: "ad-logo.png",
        technologies: ["Html", "Javascript", "CSS"]
    },
    {
        name: "Chef Betters",
        url: "https://chefbetters.net",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "food",
        description: "Chef Betters is a food blog and recipe website.",
        logo: "cb-logo.png",
        technologies: ["Wordpress", "Javascript", "CSS"]
    },
    {
        name: "DJ Smooth Holy",
        url: "https://djsmoothholy.com",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "entertainment",
        description: "DJ Smooth Holy is a music and entertainment website.",
        logo: "djsmoothholy.jpg",
        technologies: ["Wordpress", "Javascript", "CSS"]
    },
    {
        name: "Example Concept Domain",
        // url: "https://exampleconcept.com",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "business",
        description: "Example Concept is a business concept and idea website.",
        logo: "logo.png",
    },
    {
        name: "Hyrox Social Club",
        url: "https://www.hyroxsocialclub.com",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "fitness",
        description: "Hyrox Social Club is a fitness and wellness website.",
        logo: "hyrox.png",
        technologies: ["React", "Javascript", "CSS"],
        hasIframe: true
    },
    {
        name: "Insightlytics Domain",
        // url: "https://insightlytics.app",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "analytics",
        description: "Insightlytics is an analytics and data platform.",
        logo: "logo.png",
    },
    {
        name: "KidverCity",
        url: "https://kidvercity.com",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "education",
        description: "KidverCity is an educational platform for children.",
        logo: "kidvercity.png",
        technologies: ["React", "Javascript", "Firebase"]
    },
    {
        name: "Modernized Assets Domain",
        // url: "https://modernizedassets.com",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "finance",
        description: "Modernized Assets is a financial services website.",
        logo: "logo.png",
    },
    {
        name: "Patton UniverCity",
        url: "https://www.pattonunivercity.com/",
        active: true,
        category: "website",
        platform: "squarespace",
        type: "education",
        description: "Patton UniverCity is an educational platform.",
        logo: "pattonunivercity.png",
        technologies: ["React", "Javascript", "Firebase"]
    },
    {
        name: "Tri-Socialytics Domain",
        // url: "https://tri-socialytics.com",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "analytics",
        description: "Tri-Socialytics is a social media analytics platform.",
        logo: "logo.png",
    },
    {
        name: "Urban Tech Styles Domain",
        // url: "https://urbantechstyles.com",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "fashion",
        description: "Urban Tech Styles is a fashion and technology website.",
        logo: "logo.png",
    },
    {
        name: "Work Gods Domain",
        // url: "https://workgods.com",
        active: false,
        category: "domain",
        platform: "squarespace",
        type: "business",
        description: "Work Gods is a business and professional services website.",
        logo: "logo.png",
    },
    {
        name: "Writer Mind",
        url: "https://writermind.io",
        active: true,
        category: "service",
        platform: "squarespace",
        type: "writing",
        description: "Writer Mind is a writing and content creation website.",
        logo: "writermind.jpg",
        technologies: ["React", "Javascript", "Firebase"],
        hasIframe: true
    },
    // Render Apps
    {
      name: "Health Checker Dashboard",
    //   url: "https://health-checker-dashboard.onrender.com",
      active: false,
      category: "service",
      platform: "render",
      type: "health",
      hasIframe: false,
      description: "Health checker dashboard for monitoring",
      logo: "logo.png",
      technologies: ["Html", "Javascript", "CSS"]
    },
    {
        name: "AC Marketing API",
        // url: "https://acmarketing.onrender.com",
        active: false,
        category: "service",
        platform: "render",
        type: "marketing",
        hasIframe: false,
        description: "Marketing API for Ambitious Concepts",
        logo: "logo.png",
        technologies: ["Express", "Javascript", "CSS"]
    },
    {
        name: "AC Marketing UI",
        // url: "https://acmarketing-fe.onrender.com",
        active: false,
        category: "service",
        platform: "render",
        type: "marketing",
        hasIframe: false,
        logo: "logo.png",
        description: "Marketing platform for Ambitious Concepts",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "Forester",
        // url: "https://forester-0vee.onrender.com",
        active: false,
        category: "service",
        platform: "render",
        type: "logging",
        hasIframe: false,
        logo: "logo.png",
        description: "Terraform, IAM, Security Group, Route53, EC2",
        technologies: ["Terraform", "Javascript", "EC2"]
    },
    {
        name: "User Management API",
        // url: "https://usermanagement-server-jl8n.onrender.com",
        active: false,
        category: "service",
        platform: "render",
        type: "users",
        hasIframe: false,
        logo: "logo.png",
        description: "User management API",
        technologies: ["Express", "Javascript"]
    },
    {
        name: "TX Yard Games API",
        // url: "https://tx-yard-games.onrender.com",
        active: false,
        category: "service",
        platform: "render",
        type: "games",
        hasIframe: false,
        logo: "logo.png",
        description: "TX Yard Games API",
        technologies: ["Express", "Javascript"]
    },
    {
        name: "User Management UI",
        // url: "https://usermanagement-7ks2.onrender.com",
        active: false,
        category: "service",
        platform: "render",
        type: "user",
        hasIframe: false,
        logo: "logo.png",
        description: "User management platform",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "TX Yard Games UI",
        // url: "https://tx-yard-games-fe.onrender.com",
        active: false,
        category: "website",
        platform: "render",
        type: "games",
        hasIframe: false,
        logo: "logo.png",
        description: "TX Yard Games website",
        technologies: ["React", "Javascript", "CSS"]
    },
    // Vercel Apps
    {
        name: "Social DH",
        // url: "https://www.social.dominiquehosea.com/",
        category: "website",
        platform: "vercel",
        type: "social",
        hasIframe: false,
        logo: "logo.png",
        description: "Hey, you found me! I'm a social network tool website.",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "Prop Flow UI",
        url: "https://prop-flow-ui.vercel.app/",
        active: true,
        category: "service",
        platform: "vercel",
        type: "real-estate",
        hasIframe: true,
        logo: "logo.png",
        description: "Real estate management platform",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "Mun-E",
        url: "https://www.mun-e.com/",
        active: true,
        category: "website",
        platform: "vercel",
        type: "finance",
        hasIframe: true,
        logo: "logo.png",
        description: "Mun-E is a financial services website",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "PDF Master",
        active: true,
        url: "https://pdf-master-black.vercel.app/",
        category: "service",
        platform: "vercel",
        type: "utility",
        hasIframe: true,
        logo: "logo.png",
        description: "PDF Master is a PDF utility tool",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "Insightlytics",
        active: true,
        url: "https://insightlytics.vercel.app/",
        category: "service",
        platform: "vercel",
        type: "analytics",
        hasIframe: true,
        logo: "logo.png",
        description: "Insightlytics is an analytics and data platform",
        technologies: ["React", "Python", "CSS"]
    },
    {
        name: "Luminary APP",
        url: "https://luminary-gamma.vercel.app/",
        active: true,
        category: "website",
        platform: "vercel",
        type: "business",
        hasIframe: true,
        description: "Luminary is a business management platform",
        logo: "logo.png",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "KidverCity Staging",
        url: "https://staging.kidvercity.com/",
        active: true,
        category: "service",
        platform: "vercel",
        type: "education",
        hasIframe: true,
        logo: "kidvercity.png",
        description: "KidverCity is an educational platform for children.",
        technologies: ["React", "Javascript", "Firebase"]
    },
    {
        name: "User Management Vercel",
        url: "https://user-management-iota.vercel.app/",
        active: true,
        category: "service",
        platform: "vercel",
        type: "auth",
        hasIframe: true,
        logo: "logo.png",
        description: "User management platform",
        technologies: ["React", "Javascript", "Firebase"]
    },
    {
        name: "Woodsman",
        url: "https://woodsman.vercel.app/",
        active: true,
        category: "service",
        platform: "vercel",
        type: "business",
        hasIframe: true,
        logo: "woodsman.png",
        description: "Woodsman is a business management platform",
        technologies: ["Angular", "Javascript", "Firebase"]
    },
    {
        name: "ChatGPT Clone",
        url: "https://chatgpt.ambitiousconceptslabs.com/",
        active: true,
        category: "service",
        platform: "vercel",
        type: "ai",
        logo: "logo.png",
        description: "ChatGPT clone",
        technologies: ["React", "Javascript", "Firebase"],
        hasIframe: true
    },
    {
        name: "Career Compose",
        url: "https://www.careercompose.com/",
        releaseDate: "January 15, 2025",
        active: false,
        category: "service",
        platform: "vercel",
        type: "career",
        hasIframe: false,
        logo: "careercompose.png",
        description: "AI resume builder and career platform", 
        technologies: ["React", "Javascript", "Firebase"]
    },
    {
        name: "Pure CSS",
        url: "https://pure-css-chi.vercel.app/",
        active: true,
        category: "website",
        platform: "vercel",
        type: "design",
        logo: "logo.png",
        description: "Pure CSS code examples",
        hasIframe: true,
        technologies: ["CSS"]
    },      
    // AWS Services
    {
      name: "PartTimePulse",
      category: "service",
      active: true,
      platform: "aws",
      type: "automation",
      logo: "logo.png",
      description: "Terraform, Lambda, EventBridge Scheduler, Brevo (SMS & Email)",
      technologies: ["Terraform", "Python", "Javascript", "Lambda", "EventBridge", "Brevo"]
    },
    {
        name: "Ambitious Concepts .net Domain",
        category: "domain",
        active: false,
        platform: "aws",
        type: "infrastructure",
        description: "Domain for Ambitious Concepts",
        logo: "logo.png"
    },  
    // NPM
    {
        name: "AI Quiz",
        url: "https://www.npmjs.com/package/ai-quiz",
        active: true,
        category: "package",
        platform: "npm",
        type: "education",
        description: "AI Quiz package",
        logo: "logo.png",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "UI Heat Components",
        url: "https://www.npmjs.com/package/uiheat",
        active: true,
        category: "package",
        platform: "npm",
        type: "ui",
        description: "UI components for UI Heat",
        logo: "uiheat.png",
        technologies: ["React", "Javascript", "CSS"]
    },
    // Heroku (Ambitious Concepts)
    {
        name: "AC Lead Generation API",
        // url: "https://acleadgeneration-d6957f321f1b.herokuapp.com/docs",
        active: false,
        category: "service",
        platform: "heroku",
        type: "business",
        description: "Lead generation platform",
        logo: "logo.png",
        technologies: ["Python"],
        hasIframe: true
    },
    // Heroku (dominique11h)
    {
        name: "Analytics iOS",
        // url: "https://analytics-ios.herokuapp.com/",
        active: false,
        category: "service",
        platform: "heroku",
        type: "analytics",
        description: "iOS analytics platform",
        logo: "logo.png",
        technologies: ["Python"]
    },
    {
        name: "Hosea Codes Dev",
        url: "https://hoseacodes-dev-1e5b3c40880a.herokuapp.com/",
        active: true,
        category: "website",
        platform: "heroku",
        type: "portfolio",
        description: "Development version of portfolio",
        logo: "logo.png",
        technologies: ["React", "Javascript", "CSS"]
    },
    {
        name: "Patton UniverCity Heroku",
        url: "https://pattonunivercity.herokuapp.com/",
        active: true,
        category: "website",
        platform: "heroku",
        type: "education",
        description: "Educational platform",
        logo: "pattonunivercity.png",
        technologies: ["React", "Javascript", "Firebase"]
    },
];

export const themes = {
    default: {
      primary: {
        light: 'bg-blue-500',
        dark: 'bg-blue-700',
        text: 'text-blue-50'
      },
      secondary: {
        light: 'bg-purple-500',
        dark: 'bg-purple-700',
        text: 'text-purple-50'
      },
      background: {
        light: 'bg-gray-50',
        dark: 'bg-gray-900'
      },
      text: {
        light: 'text-gray-900',
        dark: 'text-white'
      }
    },
    techDark: {
      primary: {
        light: 'bg-sky-600',
        dark: 'bg-sky-800',
        text: 'text-sky-50'
      },
      secondary: {
        light: 'bg-indigo-600',
        dark: 'bg-indigo-950',
        text: 'text-indigo-50'
      },
      background: {
        light: 'bg-neutral-200',
        dark: 'bg-neutral-950'
      },
      text: {
        light: 'text-neutral-950',
        dark: 'text-neutral-50'
      }
    },
    cyberpunk: {
      primary: {
        light: 'bg-cyan-500',
        dark: 'bg-cyan-800',
        text: 'text-cyan-50'
      },
      secondary: {
        light: 'bg-fuchsia-600',
        dark: 'bg-fuchsia-900',
        text: 'text-fuchsia-50'
      },
      background: {
        light: 'bg-slate-200',
        dark: 'bg-slate-950'
      },
      text: {
        light: 'text-slate-950',
        dark: 'text-slate-100'
      }
    },
    gaming: {
      primary: {
        light: 'bg-lime-500',
        dark: 'bg-lime-700',
        text: 'text-lime-50'
      },
      secondary: {
        light: 'bg-zinc-800',
        dark: 'bg-zinc-950',
        text: 'text-zinc-50'
      },
      background: {
        light: 'bg-zinc-200',
        dark: 'bg-zinc-900'
      },
      text: {
        light: 'text-zinc-950',
        dark: 'text-zinc-50'
      }
    },
    emerald: {
      primary: {
        light: 'bg-emerald-500',
        dark: 'bg-emerald-700',
        text: 'text-emerald-50'
      },
      secondary: {
        light: 'bg-teal-500',
        dark: 'bg-teal-700',
        text: 'text-teal-50'
      },
      background: {
        light: 'bg-emerald-50',
        dark: 'bg-emerald-900'
      },
      text: {
        light: 'text-emerald-900',
        dark: 'text-emerald-50'
      }
    },
    rose: {
      primary: {
        light: 'bg-rose-500',
        dark: 'bg-rose-700',
        text: 'text-rose-50'
      },
      secondary: {
        light: 'bg-pink-500',
        dark: 'bg-pink-700',
        text: 'text-pink-50'
      },
      background: {
        light: 'bg-rose-50',
        dark: 'bg-rose-900'
      },
      text: {
        light: 'text-rose-900',
        dark: 'text-rose-50'
      }
    },
    hosea: {
      primary: {
        light: 'bg-[#7D9B6B]', // Logo green color
        dark: 'bg-[#5A7049]',  // Darker shade of the logo green
        text: 'text-white'
      },
      secondary: {
        light: 'bg-[#252A2E]', // Dark background color
        dark: 'bg-[#1F2327]',  // Navigation dark color
        text: 'text-white'
      },
      background: {
        light: 'bg-[#23292f]',
        dark: 'bg-[#23292f]'
      },
      text: {
        light: 'text-rose-900',
        dark: 'text-rose-50'
      }
    }
  };